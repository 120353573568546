import "./login.css"

import Header from "../components/header"
import Question1 from "../components/Questions/Question1"
import Question2A from "../components/Questions/Question2A"
import Question2B from "../components/Questions/Question2B"
import Question2C from "../components/Questions/Question2C"
import React from "react"
import firebase from "gatsby-plugin-firebase"
import img from "../images/gatsby-icon.png"
import { navigate } from "gatsby"

class TransApp extends React.Component {
  constructor() {
    super()

    this.state = {
      loggedIn: false,
      Question1: true,
      Question2A: false,
      Question2B: false,
      Question2C: false,
    }

    this.question1 = this.question1.bind(this)
    this.question2A = this.question2A.bind(this)
    this.question2B = this.question2B.bind(this)
    this.question2C = this.question2C.bind(this)
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        // console.log("Logged In " + user.email);
        this.setState({ loggedIn: true })
      } else {
        this.setState({ loggedIn: false })
      }
    })
  }

  clickedHeader() {
    this.props.renderHeader()
  }

  question1() {
    this.setState({
      Question1: true,
      Question2A: false,
      Question2B: false,
      Question2C: false,
    })
  }

  question2A() {
    this.setState({
      Question1: false,
      Question2A: true,
      Question2B: false,
      Question2C: false,
    })
  }

  question2B() {
    this.setState({
      Question1: false,
      Question2A: false,
      Question2B: true,
      Question2C: false,
    })
  }

  question2C() {
    this.setState({
      Question1: false,
      Question2A: false,
      Question2B: false,
      Question2C: true,
    })
  }

  render() {
    if (this.state.Question1 === true) {
      return (
        <div className="AppContainer">
          <Header />
          <div className="CardContainer">
            <Question1 next={this.question2A} />
          </div>
        </div>
      )
    } else if (this.state.Question2A === true) {
      return (
        <div className="AppContainer">
          <Header />
          <div className="CardContainer">
            <Question2A previous={this.question1} next={this.question2B} />
          </div>
        </div>
      )
    } else if (this.state.Question2B === true) {
      return (
        <div className="AppContainer">
          <Header />
          <div className="CardContainer">
            <Question2B previous={this.question2A} next={this.question2C} />
          </div>
        </div>
      )
    } else if (this.state.Question2C === true) {
      return (
        <div className="AppContainer">
          <Header />
          <div className="CardContainer">
            <Question2C previous={this.question2B} />
          </div>
        </div>
      )
    }
  }
}

export default TransApp
