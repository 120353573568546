import "./Questions.css";

import React from "react";
import firebase from "gatsby-plugin-firebase"

class Question2A extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            Question2A: "",
            answer: ""
        };

        this.HandleQuestion = this.HandleQuestion.bind(this);
        this.HandlePrevious = this.HandlePrevious.bind(this);
    }

    componentDidMount() {
        const db = firebase.firestore();

        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                console.log(user.email);
                this.setState({ email: user.email });

                // Getting Questions - Start

                var questionRef = db.collection("Questions").doc(`Questions`);
                var getDoc = questionRef
                    .get()
                    .then(doc => {
                        if (!doc.exists) {
                            console.log("No such document!");
                        } else {
                            this.setState({ Question2A: doc.get("Question2A") });
                        }
                    })
                    .catch(err => {
                        console.log("Error getting document", err);
                    });

                // Getting Questions - End

                // Getting Answers - Start

                var answerRef = db.collection("Users").doc(`${this.state.email}`);
                var getDoc = answerRef
                    .get()
                    .then(doc => {
                        if (!doc.exists) {
                            console.log("No such document!");
                        } else {
                            console.log("Document data:", doc.get("Question2A"));

                            this.setState({ answer: doc.get("Question2A") });
                        }
                    })
                    .catch(err => {
                        console.log("Error getting document", err);
                    });

                // Getting Answers - End
            } else {
                // No user is signed in.
            }
        });
    }

    updateInput = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    HandleQuestion = e => {
        e.preventDefault();
        const db = firebase.firestore();

        var email = this.state.email;
        console.log(email);
        var answer = this.state.answer;
        console.log(answer);

        var userRef = db.collection("Users").doc(`${email}`);

        // Set the "capital" field of the city 'DC'
        return userRef
            .update({
                Question2A: " " + answer
            })
            .then(() => {
                this.props.next();
                console.log("Document successfully updated!");
            })
            .catch(function (error) {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error);
            });
    };

    HandlePrevious(e) {
        e.preventDefault();
        const db = firebase.firestore();

        var email = this.state.email;
        console.log(email);
        var answer = this.state.answer;
        var password = this.state.password;

        var userRef = db.collection("Users").doc(`${email}`);

        // Set the "capital" field of the city 'DC'
        return userRef
            .update({
                Question2A: " " + answer
            })
            .then(() => {
                this.props.previous();
                console.log("Document successfully updated!");
            })
            .catch(function (error) {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error);
            });
    }

    render() {
        return (
            <div>
                <h1 className="QuestionNumber">Question 2A</h1>
                <progress value="50" max="100" />
                <h3 className="QuestionTitle">{this.state.Question2A}</h3>
                <form>
                    <textarea
                        type="text"
                        name="answer"
                        placeholder="Your Answer Here"
                        onChange={this.updateInput}
                        value={this.state.answer}
                    />

                    <div className="ButtonCont2">
                        <button className="NavButtons" onClick={this.HandlePrevious}>
                            Previous
            </button>
                        <button className="NavButtons" onClick={this.HandleQuestion}>
                            Next
            </button>
                    </div>
                </form>
            </div>
        );
    }
}

export default Question2A;
